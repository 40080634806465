import React from 'react';
import './index.scss';

class ShowDetails extends React.Component {
   render() {
      return this.props.data.map((data, index) => {
         return (
            <div key={index}>
               <div className="row">
                  <div className="col show-details">
                     <p>{data.arabic}</p>
                     <p>{data.english}</p>
                     <audio controls src={data.audio} type="audio/mpeg" />
                  </div>
               </div>
               <hr />
            </div>
         );
      });
   }
}

export default ShowDetails;
