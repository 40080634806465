import React from 'react';
import './index.scss';

class Sidebar extends React.Component {
   render() {
      const dynamicSideMenu = this.props.sideWidth;

      return (
         <div id="sidebar-wrapper" style={{ width: dynamicSideMenu }}>
            <div>{this.props.children}</div>
         </div>
      );
   }
}

export default Sidebar;
