import React from 'react';
import './index.scss';
import Heading from '../heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

class FrontDisplay extends React.Component {
   render() {
      return (
         <div className="container front-display">
            <div className="row">
               <div className="col">
                  <Heading
                     title={this.props.websiteName}
                     className="website-name"
                  />
                  <h6 className="built-by">
                     <p>Listen Quran With English Translation</p>
                     <div className="row">
                        <span className="col-12">
                           <div className="text-placement">
                              <FontAwesomeIcon
                                 icon={faMinus}
                                 className="minus-icon"
                              />
                              By
                              <a
                                 href={this.props.url}
                                 rel="noopener noreferrer"
                                 target="_blank"
                              >
                                 {this.props.parentWebsite}
                              </a>
                           </div>
                        </span>
                     </div>
                  </h6>
               </div>
            </div>
         </div>
      );
   }
}

export default FrontDisplay;
