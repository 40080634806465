import React from 'react';
import './index.scss';

class DisplayList extends React.Component {
   render() {
      return this.props.data.map((data, index) => {
         return (
            <li
               onClick={() => this.props.handleClick(data.number)}
               key={index}
               className="display-list"
            >
               <p>{data.englishName}</p>
               <p>{data.name}</p>
            </li>
         );
      });
   }
}

export default DisplayList;
