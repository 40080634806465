import React from 'react';
import './index.scss';

class Content extends React.Component {
   render() {
      return (
         <div id="page-content-wrapper">
            <div className="container">{this.props.children}</div>
         </div>
      );
   }
}

export default Content;
