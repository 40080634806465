import React from 'react';
import './index.scss';

class SkeletonLoder extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         count: 0,
      };
   }

   componentDidMount() {
      this.setState({
         count: parseInt(this.props.count),
      });
   }

   render() {
      return [...Array(this.state.count)].map((index) => (
         <div
            className={`skeleton-loader ${this.props.className}`}
            key={Math.random()}
         ></div>
      ));
   }
}

export default SkeletonLoder;
