import React from 'react';
import './index.scss';

class Heading extends React.Component {
   render() {
      return (
         <h1 className={`heading ${this.props.className}`}>
            {this.props.title}
         </h1>
      );
   }
}
export default Heading;
