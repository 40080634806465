import React from 'react';
import Button from 'react-bootstrap/Button';
import Sidebar from './sidebar/index';
import Content from './content/index';
import SurahList from '../component/surah-list/index';
import Surah from '../component/surah/index';
import FrontDisplay from '../component/front-display';
import { InitGA } from "../component/google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faAngleDoubleLeft,
   faAngleDoubleRight,
   faHome,
} from '@fortawesome/free-solid-svg-icons';
import './app.scss';

class App extends React.Component {
   constructor() {
      super();

      this.state = {
         toggleWidth: false,
         id: null,
      };
   }

   componentDidMount() {
      InitGA("UA-148041590-2")
   }

   handleClick = (value) => {
      this.setState({
         id: value,
         toggleWidth: !this.state.toggleWidth,
      });
   };

   handleClickHome = () => {
      this.setState({
         id: null,
         toggleWidth: false,
      });
   };

   handleCollapse = () => {
      this.setState({
         toggleWidth: !this.state.toggleWidth,
      });
   };

   render() {
      const wrapperStyles = {
         paddingLeft: this.state.toggleWidth ? '300px' : '0',
      };

      return (
         <div id="wrapper" className="container-fluid" style={wrapperStyles}>
            <Sidebar sideWidth={wrapperStyles.paddingLeft}>
               <SurahList handleClick={this.handleClick} />
            </Sidebar>

            <div className="activity-button">
               <Button
                  onClick={this.handleCollapse}
                  className="border mt-2 ml-2 button"
               >
                  {this.state.toggleWidth ? (
                     <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  ) : (
                     <FontAwesomeIcon icon={faAngleDoubleRight} />
                  )}
               </Button>
               <Button
                  onClick={this.handleClickHome}
                  className="border mt-2 ml-1 button"
               >
                  {<FontAwesomeIcon icon={faHome} />}
               </Button>
            </div>

            <Content>
               {!this.state.id ? (
                  <FrontDisplay
                     websiteName="Pure"
                     url="https://thecharismaofkhan.vercel.app/"
                     parentWebsite="TheCharismaOfKhan"
                  />
               ) : (
                  <Surah id={this.state.id} />
               )}
            </Content>
         </div>
      );
   }
}

export default App;
