import React from 'react';
import SkeletonLoder from '../../component/skeleton-loader';
import DisplayList from '../display-list';
import './index.scss';

class SurahList extends React.Component {
   constructor() {
      super();

      this.state = {
         loading: false,
         surahList: [],
      };
   }

   componentDidMount() {
      this.setState({
         loading: true,
      });
      fetch('https://api.alquran.cloud/v1/surah')
         .then((response) => response.json())
         .then((result) => {
            this.setState({
               loading: false,
               surahList: result.data,
            });
         });
   }

   render() {
      return this.state.loading ? (
         <SkeletonLoder count="3" className="sidebar-skeleton-loader" />
      ) : (
         <DisplayList
            data={this.state.surahList}
            handleClick={this.props.handleClick}
         />
      );
   }
}

export default SurahList;
