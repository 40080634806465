import React from 'react';
import SkeletonLoder from '../../component/skeleton-loader';
import ShowDetails from '../show-details';
import './index.scss';

class Surah extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: true,
         data: [
            {
               audio: null,
               arabic: null,
               english: null,
            },
         ],
      };

      this.fetchData = this.fetchData.bind(this);
   }

   componentDidMount() {
      this.fetchData(this.props.id);
   }

   componentDidUpdate(prevProps) {
      if (this.props.id !== prevProps.id) {
         this.setState({
            loading: true,
         });
         this.fetchData(this.props.id);
      }
   }

   fetchData(value) {
      let arabicUrl = `https://api.alquran.cloud/v1/surah/${value}/ar.alafasy`;
      let englishUrl = `https://api.alquran.cloud/v1/surah/${value}/editions/en.asad`;

      fetch(arabicUrl)
         .then((response) => {
            return response.json();
         })
         .then((response) => {
            this.setState({
               data: response.data.ayahs.map((result) => {
                  return {
                     audio: result.audio,
                     arabic: result.text,
                  };
               }),
            });
            return fetch(englishUrl);
         })
         .then((response) => {
            return response.json();
         })
         .then((response) => {
            this.setState((prevState) => {
               response.data[0].ayahs.forEach((result, index) => {
                  prevState.data[index].english = result.text;
               });
               return {
                  data: prevState.data,
               };
            });
         })
         .catch((error) => {
            console.log(error);
         })
         .finally(() => {
            this.setState({
               loading: false,
            });
         });
   }

   render() {
      return this.state.loading ? (
         <SkeletonLoder count="3" className="content-skeleton-loader" />
      ) : (
         <ShowDetails data={this.state.data} />
      );
   }
}

export default Surah;
